import * as React from 'react';
import moment from 'moment';
import { Col, Image } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import { Duxfront } from '../../../global/duxfront';
import { getUrlParameter } from '../../../duxfront/plugins/dux-utils';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { ReportMenuDropdown } from './ReportMenuDropdown.jsx';
import { DateRangePicker } from '../../../duxfront/duxstrap/components/Form.jsx';
import {
  dumpDate, parseDate,
  parseRangeDate,
  reportDateRangeDispatch,
  reportDateRangeSelector,
  reportSelector,
} from '../report-utils';
import { globalObject } from '../../../global/global_object';

function DateFilter({ reportConfig }) {
  const dateRange = reportDateRangeSelector();
  const updateReportDateRange = reportDateRangeDispatch();
  const [dateRanges, setDateRanges] = React.useState(null);
  const [dateFilterType, setDateFilterType] = React.useState(null);

  const updateDateUrlParams = React.useCallback((startDate, endDate) => {
    const params = new URLSearchParams(window.location.search);
    params.set('startDate', startDate);
    params.set('endDate', endDate);
    window.history.pushState({}, '', `?${params.toString()}`);
  });

  const onDateRangeChange = React.useCallback((newStartDate, newEndDate) => {
    const startDump = dumpDate(newStartDate);
    const endDump = dumpDate(newEndDate);

    updateDateUrlParams(startDump, endDump);
    updateReportDateRange({ startDate: startDump, endDate: endDump });
  });

  React.useEffect(() => {
    if (!reportConfig) return;

    const type = reportConfig.dateFilter?.type || 'datetime';
    setDateFilterType(type);

    if (type === 'hidden') return;

    if (!dateRange) {
      let startDate = getUrlParameter('startDate');
      let endDate = getUrlParameter('endDate');

      if (type === 'monthly') {
        startDate = startDate ? moment(startDate, 'YYYY-MM-DD+HH:mm:ss') : moment().startOf('month');
        endDate = endDate ? moment(endDate, 'YYYY-MM-DD+HH:mm:ss') : moment().endOf('month');
      } else {
        startDate = startDate ? moment(startDate, 'YYYY-MM-DD+HH:mm:ss') : moment().subtract(1, 'day');
        endDate = endDate ? moment(endDate, 'YYYY-MM-DD+HH:mm:ss') : moment();
      }

      onDateRangeChange(startDate, endDate);
    }

    if (type === 'fixed' && reportConfig.dateFilter?.value?.length > 0) {
      const ranges = {};
      reportConfig.dateFilter.value.forEach((range) => {
        ranges[range.title] = parseRangeDate(range.startDate, range.endDate);
      });

      const [start, end] = parseRangeDate(
        reportConfig.dateFilter.value[0].startDate,
        reportConfig.dateFilter.value[0].endDate,
      );

      setDateRanges(ranges);
      onDateRangeChange(start, end);
    }
  }, [reportConfig]);

  return (
    <div className="d-print-none">
      {dateFilterType !== 'hidden' && dateRange && (
        <DateRangePicker
          width="300px"
          onChange={onDateRangeChange}
          startDate={parseDate(dateRange.startDate)}
          endDate={parseDate(dateRange.endDate)}
          fixedRange={dateFilterType === 'fixed'}
          ranges={dateRanges}
          timePicker={dateFilterType === 'datetime'}
          type={dateFilterType}
          language={globalObject.duxfront?.layout?.language}
        />
      )}
    </div>
  );
}

export function ReportTopBar() {
  const translator = new Translator();
  const duxfront = new Duxfront();
  const report = reportSelector(
    ['title', 'locationName', 'isTemplate', 'isPrivate', 'previousPage', 'viewMode', 'config'],
  );
  const dateRange = reportDateRangeSelector();
  const updateReportDateRange = reportDateRangeDispatch();
  const [setDateFilterFixed] = React.useState(false);
  const [setDateRanges] = React.useState(null);
  const isScada = report?.viewMode === 'scada';
  const updateDateUrlParams = React.useCallback((startDate, endDate) => {
    const params = new URLSearchParams(window.location.search);
    params.set('startDate', startDate);
    params.set('endDate', endDate);
    window.history.pushState({}, '', `?${params.toString()}`);
  });

  const onDateRangeChange = React.useCallback((newStartDate, newEndDate) => {
    const start = dumpDate(newStartDate);
    const end = dumpDate(newEndDate);

    updateDateUrlParams(start, end);
    updateReportDateRange({ startDate: start, endDate: end });
  });

  React.useEffect(() => {
    if (!report) return;

    const fixed = report.config?.dateFilter?.type === 'fixed';

    if (fixed && report.config.dateFilter?.value?.length > 0) {
      const ranges = {};
      report.config.dateFilter.value.forEach((range) => {
        ranges[range.title] = parseRangeDate(range.startDate, range.endDate);
      });

      const [start, end] = parseRangeDate(
        report.config.dateFilter.value[0].startDate,
        report.config.dateFilter.value[0].endDate,
      );
      setDateFilterFixed(fixed);
      setDateRanges(ranges);
      onDateRangeChange(start, end);
    } else if (!dateRange) {
      let startDate = getUrlParameter('startDate');
      let endDate = getUrlParameter('endDate');

      startDate = startDate ? moment(startDate, 'YYYY-MM-DD+HH:mm:ss') : moment().subtract(1, 'day');
      endDate = endDate ? moment(endDate, 'YYYY-MM-DD+HH:mm:ss') : moment();

      onDateRangeChange(startDate, endDate);
    }
  }, [report]);

  const generateReportType = React.useCallback(() => {
    if (!report) return null;
    if (report.isTemplate) return translator.get('general.report_template');

    if (report.locationName) {
      return `${translator.get('general.location_report')}: ${report.locationName}`;
    }

    if (report.isPrivate) return translator.get('general.private_report');

    return translator.get('general.public_report');
  });

  return report && (
    <>
      <Col sm={8}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div className="fs-12 text-gray-600 text-uppercase d-print-none">
              { generateReportType() }
            </div>
            <h1 className="h3 m-0 font-weight-accent text-primary">
              {report.title}
            </h1>
            <div className="fs-12 text-gray-600 d-none d-print-block">
              {report.locationName && (
                <div>
                  {`${translator.get('titles.location')}: ${report.locationName}`}
                </div>
              )}
              {/* <div> */}
              {/*  {`${translator.get('general.generated_by')}: ${user.name}`} */}
              {/* </div> */}
              <div>
                {`${translator.get('general.generated_at')}: `}
                {moment().format(translator.get('formats.basic-date-time-js'))}
              </div>
              <div>
                {dateRange && (
                  <>
                    {`${translator.get('general.date_range')}: `}
                    {parseDate(dateRange.startDate).format(translator.get('formats.basic-date-time-js'))}
                    {' - '}
                    {parseDate(dateRange.endDate).format(translator.get('formats.basic-date-time-js'))}
                  </>
                )}
              </div>
            </div>
            <Button
              withIcon
              variant="outline-primary"
              size="xs"
              className="text-uppercase d-print-none"
              href={report.previousPage}
            >
              <Icon name="arrow-left" />
              {translator.get('general.back')}
            </Button>
            <ReportMenuDropdown />
          </div>
        </div>
      </Col>
      <Col sm={4} className="d-flex align-items-center justify-content-end">
        { !isScada && (
          <DateFilter reportConfig={report.config} />
        )}
        <Image
          src={duxfront.institutional.logo}
          className="d-none d-print-block"
          style={{ maxWidth: '200px', maxHeight: '120px' }}
        />
      </Col>
    </>
  );
}
