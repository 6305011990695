import * as React from 'react';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../global/translator';
import { useLocationGroups } from '../../hooks/use-location-groups';
import { LocationGroupModal } from '../../pages/location_group/components/LocationGroupModal.jsx';
import { useLocationGroupNavigation } from '../../hooks/use-location-group-navigation';

export function LocationGroups(props) {
  const translator = new Translator();
  const { navigateGroupOptions } = useLocationGroupNavigation();
  const {
    groups,
    canEdit,
    modal,
    closeModal,
    showModal,
    create,
  } = useLocationGroups();

  return (
    <GlobalWrapper {...props}>
      <Dashbox className="h-100">
        <DashboxTitle title={translator.get('titles.location_groups')}>
          { canEdit && (
          <Button rounded icon size="sm" variant="outline-primary" onClick={() => showModal(null)}>
            <Icon name="plus" offset={1} />
          </Button>
          )}
        </DashboxTitle>
        <DashboxBody table style={{ maxHeight: '300px' }}>
          { groups && groups.length > 0 && groups.map((group) => (
            <tr key={group.id} className="cursor-pointer" onClick={() => navigateGroupOptions(group.code)}>
              <td className="px-3">
                <div className="font-weight-accent">{group.title}</div>
              </td>
            </tr>
          ))}

          { groups.length === 0 && (
          <tr>
            <td className="px-3">
              <p>{translator.get('messages.no-results-found')}</p>
            </td>
          </tr>
          )}
        </DashboxBody>
      </Dashbox>

      <LocationGroupModal
        show={modal}
        canEdit={canEdit}
        onClose={closeModal}
        onSave={create}
      />
    </GlobalWrapper>
  );
}
