import * as React from 'react';
import { Col, Table } from 'react-bootstrap';
import { patchJSON } from '../../../duxfront/plugins/dux-utils';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { HiddenInput, Input, Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Translator } from '../../../global/translator';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { ReportPlotFormWrapper } from '../report_plot_base/ReportPlotFormWrapper.jsx';
import {
  MODAL_TYPES,
  reportErrorDispatch,
  reportModalTypeSelector,
  reportPlotDispatch,
  reportPlotOptionsSelector,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';

function ReportInteractivePlotAddAxisRowButton({ plotKey, setAxisDetails }) {
  const translator = new Translator();
  const plot = reportPlotSelector(plotKey, ['updatePath']);
  const updatePlot = reportPlotDispatch(plotKey);
  const updateError = reportErrorDispatch();

  const addAxis = React.useCallback(() => {
    patchJSON(
      plot.updatePath,
      { add_object: { object_type: 'axis' } },
      (response) => {
        updatePlot(response.object);
        setAxisDetails(response.added);
      },
      (error) => updateError(error.message),
    );
  });

  return (
    <tr key="report-plot-add-button">
      <td className="px-3 text-center">
        <Button size="xs" variant="primary" onClick={addAxis} style={{ minWidth: '150px' }} withIcon>
          <Icon name="plus" />
          {translator.get('general.add').toUpperCase()}
        </Button>
      </td>
    </tr>
  );
}

export function ReportInteractivePlotAxisForm() {
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const updatePlot = reportPlotDispatch(reportPlotSelectedKey);
  const updateError = reportErrorDispatch();
  const selectedModalType = reportModalTypeSelector();
  const show = plot && selectedModalType === MODAL_TYPES.INTERACTIVE_PLOT_AXIS;
  const formId = 'interactive_plot_axis';
  const title = `${translator.get('general.edit')} ${translator.get('general.axis')}`;
  const [axisDetails, setAxisDetails] = React.useState(null);

  const removeAxis = React.useCallback(() => {
    patchJSON(
      plot.updatePath,
      { remove_object: { object_type: 'axis', object_id: axisDetails.id } },
      (response) => {
        setAxisDetails(null);
        updatePlot(response.object);
      },
      (error) => updateError(error.message),
    );
  });

  React.useEffect(() => {
    if (!plot) setAxisDetails(null);
  });

  return show && (
    <ReportPlotModalWrapper
      title={title}
      modalType={MODAL_TYPES.INTERACTIVE_PLOT_AXIS}
    >
      { !axisDetails && (
        <Table size="sm" className="m-0 border-top" hover>
          <tbody>
            {plot.config.axis.map((details) => (
              <tr key={details.id} className="cursor-pointer" onClick={() => setAxisDetails(details)}>
                <td className="px-3">
                  <div className="font-weight-accent text-primary">{details.label}</div>
                </td>
              </tr>
            ))}
            <ReportInteractivePlotAddAxisRowButton
              plotKey={plot.key}
              setAxisDetails={setAxisDetails}
            />
          </tbody>
        </Table>
      )}
      { !!axisDetails && (
        <ReportPlotFormWrapper
          formId={formId}
          onRemove={removeAxis}
          afterSave={() => setAxisDetails(null)}
        >
          <HiddenInput
            id="id"
            formId={formId}
            defaultValue={axisDetails?.id}
          />
          <Col sm={8}>
            <Input
              id="label"
              formId={formId}
              label={translator.get('general.title')}
              defaultValue={axisDetails?.label}
              placeholder="Daily Temperature (°C)"
              validation="required"
            />
          </Col>
          <Col xs={4}>
            <Select
              id="position"
              formId={formId}
              label={translator.get('general.position')}
              defaultValue={axisDetails?.position}
              options={plotOptions.plotAxisPositionOptions}
            />
          </Col>
        </ReportPlotFormWrapper>
      )}
    </ReportPlotModalWrapper>
  );
}
