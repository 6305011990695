import * as React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Input } from '../../../duxfront/duxstrap/components/Form.jsx';

export function LocationGroupAssigmentModal({
  locations,
  option,
  show,
  onClose,
  onAssign,
  onUnassign,
}) {
  const self = React.createRef();
  const translator = new Translator();
  const [filter, setFilter] = React.useState('');
  const [originalLocations, setOriginalLocations] = React.useState([]);
  const [filteredLocations, setFilteredLocations] = React.useState([]);

  React.useEffect(() => {
    if (!locations) return;

    const assignedLocations = option?.assignments.map((l) => l.location.code);
    const locationWithAssignment = locations.map((location) => ({
      ...location,
      assigned: assignedLocations.includes(location.code),
    }));

    setOriginalLocations(locationWithAssignment);
  }, [option, locations]);

  React.useEffect(() => {
    if (!originalLocations) {
      return;
    }

    setFilteredLocations(
      originalLocations.filter((location) => location.name.toLowerCase().includes(filter.toLowerCase())),
    );
  }, [originalLocations, filter]);

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>
          { `${translator.get('general.add')} ${translator.get('titles.location_group_assignment')}` }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="p-3" style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Row>
          <Col sm={12} md={8}>
            <Input label={translator.get('general.name')} onChange={(e) => setFilter(e.target.value)} />
          </Col>
        </Row>

        <table className="table table-sm table-break-word table-hover mb-0 mt-2">
          <tbody>
            {!filteredLocations || filteredLocations.length === 0 ? (
              <tr>
                <td className="px-3">{translator.get('messages.no-results-found')}</td>
              </tr>
            ) : (
              filteredLocations.map((l) => (
                <tr key={l.code}>
                  <td>{l.name}</td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="d-flex justify-content-end">
                    {l.assigned ? (
                      <Button rounded icon size="sm" variant="outline-danger" onClick={() => onUnassign(l)}>
                        <Icon name="minus" offset={1} />
                      </Button>
                    ) : (
                      <Button rounded icon size="sm" variant="outline-primary" onClick={() => onAssign(l)}>
                        <Icon name="plus" offset={1} />
                      </Button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}
