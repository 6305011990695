import { Project } from '../global/project';

export function useLocationGroupNavigation() {
  const project = new Project();

  function navigateProjectDetails() {
    window.location.href = `/projects/${project.code}/details`;
  }

  function navigateGroupOptions(groupId) {
    window.location.href = `/projects/${project.code}/location_groups/${groupId}`;
  }

  function navigateGroupAssignments(optionId) {
    window.location.href = `/projects/${project.code}/location_groups/options/${optionId}`;
  }

  return {
    navigateGroupOptions,
    navigateProjectDetails,
    navigateGroupAssignments,
  };
}
