import * as React from 'react';
import { Row, Container } from 'react-bootstrap';
import { ReportTopBar } from './report_base/ReportTopBar.jsx';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { ReportInitialLoader } from './report_base/ReportInitialLoader.jsx';
import { ReportPlot } from './report_plot_base/ReportPlot.jsx';
import { ReportAdvancedForm } from './report_base/ReportAdvancedForm.jsx';
import { ReportEditCustomFieldsForm } from './report_base/ReportEditCustomFieldsForm.jsx';
import { ReportApplyTemplateForm } from './report_base/ReportApplyTemplateForm.jsx';
import { ReportPlotGeneralForm } from './report_plot_base/ReportPlotGeneralForm.jsx';
import { ReportInteractivePlotDataForm } from './report_interactive_plot/ReportInteractivePlotDataForm.jsx';
import { ReportInteractivePlotAxisForm } from './report_interactive_plot/ReportInteractivePlotAxisForm.jsx';
import { ReportTablePlotDataForm } from './report_table_plot/ReportTablePlotDataForm.jsx';
import { ReportGenericPlotDataForm } from './report_plot_other/ReportGenericPlotDataForm.jsx';
import { ReportPlotAdvancedForm } from './report_plot_base/ReportPlotAdvancedForm.jsx';
import { ReportError } from './report_base/ReportError.jsx';
import { ReportScada } from './report_scada/ReportScada.jsx';
import { ReportMapsModal } from './report_plot_map/ReportMapsModal.jsx';
import { reportSelector, reportPlotKeysSelector } from './report-utils';
import { ReportDataSet } from './report_base/ReportDataSet.jsx';

function Report() {
  const plotKeys = reportPlotKeysSelector() || [];
  const report = reportSelector(['viewMode']);

  if (report && report.viewMode === 'scada') return <ReportScada />;

  return (
    <Container fluid className="py-3 px-md-4" id="container-print">
      <Row className="gutter-2 mb-2">
        <ReportTopBar />
        <ReportEditCustomFieldsForm />
        { plotKeys.map((plotKey) => (
          <ReportPlot key={plotKey} plotKey={plotKey} />
        ))}
      </Row>
      <ReportError />
      <ReportAdvancedForm />
      <ReportDataSet />
      <ReportApplyTemplateForm />
      <ReportPlotGeneralForm />
      <ReportInteractivePlotDataForm />
      <ReportInteractivePlotAxisForm />
      <ReportTablePlotDataForm />
      <ReportGenericPlotDataForm />
      <ReportPlotAdvancedForm />
      <ReportMapsModal />
    </Container>
  );
}

export function ReportView(props) {
  const {
    report,
    plotOptions,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <ReportInitialLoader report={report} plotOptions={plotOptions} />
      <Report />
    </GlobalWrapper>
  );
}
