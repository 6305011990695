import React from 'react';
import {
  reportErrorDispatch, reportScadaNewConnectionDispatch,
  reportScadaNewConnectionSelector, reportSelector,
} from '../report-utils';
import { patchJSON } from '../../../duxfront/plugins/dux-utils';
import { ReportPlotNew } from '../report_plot_base/ReportPlotNew.jsx';
import { Authenticity } from '../../../global/authenticity';

export const ReportScadaNewConnection = React.memo(() => {
  const payload = reportScadaNewConnectionSelector();
  const updatePayload = reportScadaNewConnectionDispatch();
  const authenticityToken = new Authenticity().token();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const [newPlot, setNewPlot] = React.useState(null);

  const addScadaConnection = React.useCallback(() => {
    patchJSON(
      report.updatePath,
      {
        authenticity_token: authenticityToken,
        add_plot: {
          plot_type: 'scada_connection',
          scada_connection: payload,
        },
      },
      (result) => {
        updatePayload(null);
        setNewPlot(result.added);
      },
      () => updateError('Failed to add new plot.'),
    );
  });

  React.useEffect(() => {
    if (!payload) return;

    addScadaConnection();
  });

  return newPlot && (
    <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
  );
});
