import * as React from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { Dashbox, DashboxBody } from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Project } from '../../../global/project';
import { Translator } from '../../../global/translator';
import {
  ReportPlotBoxFooter,
  ReportPlotBoxFullScreenIcon,
  ReportPlotBoxHeader,
} from '../report_plot_base/ReportPlotBox.jsx';
import { ReportPlotDataLoader } from '../report_plot_base/ReportPlotDataLoader.jsx';
import { ReportPlotRefreshData } from '../report_plot_base/ReportPlotRefreshData.jsx';
import { ReportPlotClone } from '../report_plot_base/ReportPlotClone.jsx';
import { ReportPlotDelete } from '../report_plot_base/ReportPlotDelete.jsx';
import { ReportPlotAdvanced } from '../report_plot_base/ReportPlotAdvanced.jsx';
import {
  MODAL_TYPES,
  reportModalTypeDispatch,
  reportPlotDataSelector,
  reportPlotMapsSelector,
  reportPlotSelectedKeyDispatch,
  reportPlotSelector,
} from '../report-utils';
import LocationsMap from '../../maps/LocationsMap.jsx';
import { plotConfigSelector } from '../report_interactive_plot/plot-utils';

export function ReportMapPlot({ plotKey }) {
  const plot = reportPlotSelector(plotKey, ['key']);
  const boxContentRef = React.useRef();

  return plot && (
    <>
      <ReportPlotDataLoader
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
      <ReportMapPlotBox
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
    </>
  );
}

function ReportMapPlotBox({ plotKey, boxContentRef }) {
  const { plot, plotConfig } = plotConfigSelector(plotKey);
  const [fullScreen, setFullScreen] = React.useState(false);

  return plotConfig && !plotConfig.hidden && (
    <Col sm={plot.columnSize} className="print-no-page-break" style={{ order: plot.order }}>
      <Dashbox fullViewPort={fullScreen}>
        <ReportMapPlotHeader
          plotKey={plot.key}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          boxContentRef={boxContentRef}
        />
        <ReportMapPlotBody
          plotKey={plot.key}
          boxContentRef={boxContentRef}
        />
        <ReportPlotBoxFooter plotKey={plot.key} />
      </Dashbox>
    </Col>
  );
}

function ReportMapPlotHeader({
  plotKey, fullScreen, setFullScreen,
}) {
  const plot = reportPlotSelector(plotKey, ['key', 'title']);
  const project = new Project();
  const translator = new Translator();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();

  const launchModal = React.useCallback((modalType) => {
    updateSelectedPlotKey(plot.key);
    updateSelectedModalType(modalType);
  });

  return (
    <ReportPlotBoxHeader plotKey={plot.key} className="d-flex align-items-center">
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <Icon
        name="map"
        className="cursor-pointer p-0 mr-2"
        onClick={() => launchModal(MODAL_TYPES.PROJECT_MAP_SELECTION)}
      />
      <ReportPlotBoxFullScreenIcon fullScreen={fullScreen} setFullScreen={setFullScreen} />
      { project.userCan('edit_reports') && (
        <Dropdown as="div" className="d-inline-block cursor-pointer ml-2">
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <Icon name="ellipsis-v" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ReportPlotRefreshData plotKey={plot.key} />
            <Dropdown.Item onClick={() => launchModal(MODAL_TYPES.PLOT_GENERAL)}>
              <Icon name="cogs" className="text-primary mr-2" />
              { translator.get('general.settings') }
            </Dropdown.Item>
            <ReportPlotClone plotKey={plot.key} />
            <ReportPlotDelete plotKey={plot.key} />
            <ReportPlotAdvanced plotKey={plot.key} />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ReportPlotBoxHeader>
  );
}

function ReportMapPlotBody({
  plotKey,
  boxContentRef,
}) {
  const plot = reportPlotSelector(plotKey);
  const plotData = reportPlotDataSelector(plotKey);
  const maps = reportPlotMapsSelector(plotKey);

  return (
    <DashboxBody
      ref={boxContentRef}
      style={{ height: plot.height }}
      className="p-0"
    >
      <LocationsMap
        locations={plotData?.data?.locations ?? []}
        maps={maps ?? []}
      />
    </DashboxBody>
  );
}
