import { Dropdown } from 'react-bootstrap';
import React from 'react';

export function ContextMenuItem({ children, ...props }) {
  return (
    <Dropdown.Item {...props}>
      { children }
    </Dropdown.Item>
  );
}

export function ContextMenu({
  clickX, clickY, children, marginX = 150, marginY = 150, style = {}, ...props
}) {
  const openLeft = clickX >= (window.innerWidth - marginX);
  const openTop = clickY >= (window.innerHeight - marginY);
  const top = !openTop && clickY;
  const bottom = openTop && (window.innerHeight - clickY);
  const left = !openLeft && clickX;
  const right = openLeft && (window.innerWidth - clickX);

  return (
    <div
      className="context-menu"
      style={{
        top, bottom, left, right, ...style,
      }}
      {...props}
    >
      { children }
    </div>
  );
}
