import * as tog from '@tmcw/togeojson';
import { DOMParser } from '@xmldom/xmldom';
import { createRoot } from 'react-dom/client';

export const mapWeightOptions = [1, 2, 3, 4, 5].map((w) => ({ value: w, name: w }));

export const createMap = (ref) => new global.window.google.maps.Map(ref.current, {
  mapId: 'LOCATION_MAP',
  center: { lat: -34.397, lng: 150.644 },
  zoom: 8,
  mapTypeId: 'satellite',
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: true,
});

export const createMarker = (map, location) => {
  const markerTag = document.createElement('div');
  markerTag.className = 'map-marker-tag';
  markerTag.textContent = location.name;

  const marker = new global.window.google.maps.marker.AdvancedMarkerElement({
    map,
    title: location.name,
    content: markerTag,
    position: new global.window.google.maps.LatLng({
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude),
    }),
  });

  return marker;
};

export const createMarkerWithWindow = (map, location, windowComponent) => {
  const infoWindowId = `infoWindow-${location.code}`;

  const info = new global.window.google.maps.InfoWindow({
    content: `<div id="${infoWindowId}" />`,
  });

  let markerIcon = null;

  if (location.mapIcon) {
    markerIcon = document.createElement('img');
    markerIcon.src = location.mapIcon;
  }

  const marker = new global.window.google.maps.marker.AdvancedMarkerElement({
    map,
    title: location.name,
    content: markerIcon,
    position: new global.window.google.maps.LatLng({
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude),
    }),
  });

  const openInfoWindow = () => {
    info.open({
      anchor: marker,
      map,
    });

    setTimeout(() => {
      const container = global.window.document.getElementById(infoWindowId);
      const root = createRoot(container);
      root.render(windowComponent({ location }));

      info.addListener('closeclick', () => {
        root.unmount();
      });
    }, 1);
  };

  marker.addListener('click', openInfoWindow);

  if (location.mapWindowOpen) {
    setTimeout(() => openInfoWindow(), 1000);
  }

  return marker;
};

export const convertKmlToGeoJson = (kml) => {
  // eslint-disable-next-line no-undef
  const parser = new DOMParser();
  const xml = parser.parseFromString(kml, 'text/xml');

  return JSON.stringify(tog.kml(xml));
};

export const updateGeoJson = (projectMap) => {
  const geojson = JSON.parse(projectMap.content);

  if (projectMap.color) {
    geojson.features.forEach((feature) => {
      // eslint-disable-next-line no-param-reassign
      feature.properties.stroke = projectMap.color ?? feature.properties.stroke;
      // eslint-disable-next-line no-param-reassign
      feature.properties.fill = projectMap.color ?? feature.properties.fill;
    });
  }

  if (projectMap.weight) {
    geojson.features.forEach((feature) => {
      // eslint-disable-next-line no-param-reassign
      feature.properties.strokeWeight = projectMap.weight ?? feature.properties.strokeWeight;
    });
  }

  return geojson;
};

export const setProjectMaps = (map, maps) => {
  map.data.forEach((feature) => map.data.remove(feature));

  maps.forEach((kml) => {
    const geojson = updateGeoJson(kml);
    map.data.addGeoJson(geojson);
  });

  map.data.setStyle((feature) => ({
    strokeColor: feature.getProperty('stroke'),
    strokeWeight: feature.getProperty('strokeWeight'),
    fillColor: feature.getProperty('fill'),
  }));
};
