import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { GlobalWrapper } from '../../components/GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../global/translator';
import { LocationGroupOptionModal } from './components/LocationGroupOptionModal.jsx';
import { LocationGroupModal } from './components/LocationGroupModal.jsx';
import { useLocationGroupNavigation } from '../../hooks/use-location-group-navigation';
import { useLocationGroups } from '../../hooks/use-location-groups';
import { useLocationGroupOptions } from '../../hooks/use-location-group-options';

export function LocationGroupDetails(props) {
  const { locationGroup } = props;
  return (
    <GlobalWrapper {...props}>
      <LocationGroupDetailsView locationGroup={locationGroup} />
    </GlobalWrapper>
  );
}

function LocationGroupDetailsView({ locationGroup }) {
  const translator = new Translator();
  const { navigateProjectDetails, navigateGroupAssignments } = useLocationGroupNavigation();
  const {
    modal: editGroupModal,
    closeModal: editGroupCloseModal,
    showModal: editGroupShowModal,
    update: updateGroup,
    destroy: destroyGroup,
  } = useLocationGroups();

  const {
    group,
    setGroup,
    canEdit,
    modal: newOptionModal,
    showModal: showNewOptionModal,
    closeModal: closeNewOptionModal,
    create: createOption,
  } = useLocationGroupOptions(locationGroup);

  const onEditGroup = (title) => {
    updateGroup(group.code, title);
    setGroup({ ...group, title });
  };

  const onDestroyGroup = () => {
    destroyGroup(group.code);
  };

  return group && (
  <Container className="py-3">
    <Row>
      <Col sm={12}>
        <div className="fs-12 text-gray-600 text-uppercase">{translator.get('titles.location_group')}</div>
        <h4 className="font-weight-accent m-0">
          {group.title}
        </h4>
      </Col>

      <Col sm={12} className="d-flex mt-1">
        <Button withIcon size="xs" variant="outline-primary" onClick={navigateProjectDetails}>
          <Icon name="arrow-left" />
          {translator.get('general.back').toUpperCase()}
        </Button>

        {canEdit && (
        <>
          <Button
            withIcon
            size="xs"
            variant="outline-danger"
            className="ml-2"
            onClick={onDestroyGroup}
          >
            <Icon name="trash" />
            {translator.get('general.remove').toUpperCase()}
          </Button>

          <Button
            withIcon
            size="xs"
            variant="outline-secondary"
            className="ml-2"
            onClick={() => editGroupShowModal(group)}
          >
            <Icon name="edit" />
            {translator.get('general.edit').toUpperCase()}
          </Button>
        </>
        )}
      </Col>

      <Col sm={12} className="mt-3">
        <Dashbox>
          <DashboxTitle title={translator.get('general.options')}>
            {canEdit && (
            <Button rounded icon size="sm" variant="outline-primary" onClick={showNewOptionModal}>
              <Icon name="plus" offset={1} />
            </Button>
            )}
          </DashboxTitle>

          <DashboxBody table>
            {!group.options || group.options.length === 0 ? (
              <tr>
                <td className="px-3">{translator.get('messages.no-results-found')}</td>
              </tr>
            ) : (
              group.options.map((option) => (
                <tr
                  key={option.id}
                  className="link-wrapper cursor-pointer"
                  onClick={() => navigateGroupAssignments(option.code)}
                >
                  <td className="px-3 d-flex flex-column">
                    <span>{option.title}</span>
                    <span className="fs-12 text-gray-600">
                      {option.assignments.length}
                      {' '}
                      {option.assignments.length === 1
                        ? translator.get('general.assignment').toLowerCase()
                        : translator.get('general.assignments').toLowerCase()}
                    </span>
                  </td>
                </tr>
              ))
            )}
          </DashboxBody>
        </Dashbox>
      </Col>
    </Row>

    <LocationGroupOptionModal
      show={newOptionModal}
      onClose={closeNewOptionModal}
      onSave={createOption}
    />

    <LocationGroupModal
      group={group}
      show={editGroupModal}
      onClose={editGroupCloseModal}
      onSave={onEditGroup}
    />
  </Container>
  );
}
