import * as React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Input } from '../../../duxfront/duxstrap/components/Form.jsx';
import { Button } from '../../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../../global/translator';

export function LocationGroupModal({
  show, group, onClose, onSave,
}) {
  const self = React.createRef();
  const translator = new Translator();
  const [title, setTitle] = React.useState(group?.title ?? '');

  const saveGroup = () => {
    onSave(title);
  };

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>
          { group ? `${translator.get('general.edit')} ` : `${translator.get('general.add')} `}
          {translator.get('titles.location_group')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={self} className="pt-2 px-3">
        <Row>
          <Col sm={12}>
            <Input
              id="title"
              label={translator.get('general.title')}
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={6}>
            <Button size="sm" variant="gray-200" onClick={onClose} withIcon block>
              <Icon name="times" />
              { translator.get('general.cancel').toUpperCase() }
            </Button>
          </Col>
          <Col sm={6}>
            <Button size="sm" variant="primary" onClick={saveGroup} withIcon block>
              <Icon name="check" />
              { translator.get('general.save').toUpperCase() }
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
