import * as React from 'react';
import { reportErrorSelector, reportPlotSelector } from '../report-utils';
import { BootBox } from '../../../duxfront/duxstrap/vendor/bootbox';

export function ReportError() {
  const error = reportErrorSelector();
  const plot = reportPlotSelector(error?.plotKey, ['title']);

  function message() {
    if (typeof error === 'string') return error;

    return `Error for <b> ${plot.title} </b>: <br><br> ${error.message}`;
  }

  React.useEffect(() => {
    if (!error) return;

    const alertMessage = message();

    BootBox.alert({
      message: alertMessage,
      size: alertMessage.length > 80 ? 'large' : 'small',
    });
  });

  return null;
}
