import React from 'react';
import { Project } from '../global/project';
import { postJSON } from '../duxfront/plugins/dux-utils';

export function useLocationGroupAssignment(selectedOption) {
  const project = new Project();
  const [modal, setModal] = React.useState(false);
  const [option, setOption] = React.useState(null);

  React.useEffect(() => {
    if (!selectedOption) return;
    setOption(selectedOption);
  }, [selectedOption]);

  function showModal() {
    setModal(true);
  }

  function closeModal() {
    setModal(false);
  }

  function assign(location) {
    if (!option) return;

    postJSON(
      `/projects/${project.code}/location_groups/${option.group.code}/assign`,
      { location_id: location.code, option_id: option.code },
      (data) => {
        setOption(data.object);
      },
    );
  }

  function unassign(location) {
    if (!option) return;

    postJSON(
      `/projects/${project.code}/location_groups/${option.group.code}/unassign`,
      { location_id: location.code, option_id: option.code },
      (data) => {
        setOption(data.object);
      },
    );
  }

  return {
    modal,
    showModal,
    closeModal,
    option,
    setOption,
    assign,
    unassign,
  };
}
