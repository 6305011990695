import * as React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import * as pako from 'pako';
import { saveAs } from 'file-saver';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { JSONEditor } from '../../duxfront/duxdash/components/JSONEditor.jsx';
import { GlobalWrapper } from '../GlobalWrapper.jsx';
import { globalDispatch, globalSelector } from '../../duxfront/plugins/dux-redux';
import { Translator } from '../../global/translator';
import {} from 'react_ujs';

function LogDetailsModal() {
  const selectedLog = globalSelector('selectedLog');
  const updateSelectedLog = globalDispatch('selectedLog');
  const translator = new Translator();

  const closeModal = React.useCallback(() => {
    updateSelectedLog(null);
  });

  const decodeAndDownloadFile = React.useCallback((logFile) => {
    // Requires https://github.com/nodeca/pako
    // Requires https://github.com/eligrey/FileSaver.js

    // File Details
    const fileContent = logFile.base64_gzip_content;
    const fileName = logFile.name;

    // Decode the base64 string
    const binaryString = atob(fileContent);
    const bytes = new Uint8Array(new ArrayBuffer(binaryString.length));
    for (let i = 0; i < binaryString.length; i += 1) bytes[i] = binaryString.charCodeAt(i);

    // Decompress gzip
    const decompressedArrayBuffer = pako.inflate(bytes);
    const decompressedBlob = new Blob([decompressedArrayBuffer], { type: 'application/octet-stream' });

    // Download decompressed file
    saveAs(decompressedBlob, fileName);
  });

  return (
    <Modal centered show={!!selectedLog} onHide={closeModal}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>LOG DETAILS</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2 px-3">
        <Row className="my-0 gutter-1">
          <Col md={8}>
            <div className="text-gray-500 fs-10 text-uppercase font-weight-bold">ID</div>
            <div className="fs-16">{selectedLog?.logId}</div>
          </Col>

          <Col md={4}>
            <div className="text-gray-500 fs-10 text-uppercase font-weight-bold">
              {translator.get('general.time')}
            </div>
            <div className="fs-16">{selectedLog?.logTime}</div>
          </Col>

          <Col md={12}>
            <div className="text-gray-500 fs-10 text-uppercase font-weight-bold">
              {translator.get('general.message')}
            </div>
            <div className="fs-16">{selectedLog?.logMessage}</div>
          </Col>

          { selectedLog?.logFiles && selectedLog.logFiles.length > 0 && (
            <Col md={12}>
              <div className="text-gray-500 fs-10 text-uppercase font-weight-bold">
                {translator.get('general.attachments')}
              </div>
                { selectedLog.logFiles.map((file) => (
                  <Row key={file.name} className="my-0 gutter-1">
                    <Col md={12} className="fs-1xa">
                      <Button
                        block
                        withIcon
                        size="sm"
                        variant="outline-primary"
                        onClick={() => decodeAndDownloadFile(file)}
                      >
                        <Icon name="download" offset={1} />
                        {file.name}
                      </Button>
                    </Col>
                  </Row>
                ))}
            </Col>
          )}

          <Col md={12}>
            <div className="text-gray-500 fs-10 text-uppercase font-weight-bold">
              {translator.get('general.details')}
            </div>
            <JSONEditor
              readOnly
              collapsed
              json={selectedLog?.logDetails}
              style={{ maxHeight: '40vh' }}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

function LogListItem(props) {
  const {
    logTime,
    logId,
    logSource,
    logType,
    logMessage,
    logDetails,
    logFiles,
  } = props;

  const updateSelectedLog = globalDispatch('selectedLog');

  const onClick = React.useCallback(() => {
    updateSelectedLog({
      logId,
      logTime,
      logSource,
      logType,
      logMessage,
      logDetails,
      logFiles,
    });
  });

  return (
    <tr className="log-row cursor-pointer" onClick={onClick}>
      <td className="px-3">
        <div className="font-weight-accent">{logMessage}</div>
        <div className="fs-12 text-gray-500">{logTime}</div>
      </td>
    </tr>
  );
}

function LogListTable(props) {
  const {
    logs,
  } = props;

  const translator = new Translator();

  return (
    <table className="table table-sm table-break-word table-hover mb-0">
      <tbody>
        { logs && logs.length > 0
          ? logs.map((log) => (
            <LogListItem key={log.logId} {...log} />
          ))
          : (
            <tr>
              <td className="px-3">
                <p>{translator.get('messages.no-results-found')}</p>
              </td>
            </tr>
          )}
      </tbody>
    </table>
  );
}

export function LogList(props) {
  const {
    logs,
  } = props;

  return (
    <GlobalWrapper {...props}>
      <LogListTable logs={logs} />
      <LogDetailsModal />
    </GlobalWrapper>
  );
}
