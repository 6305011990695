import React from 'react';
import {
  reportErrorDispatch,
  reportPlotDispatch,
  reportPlotSelector,
  reportScadaUpdateDispatch,
  reportScadaUpdateSelector,
} from '../report-utils';
import { patchJSON } from '../../../duxfront/plugins/dux-utils';

export const ReportScadaPlotUpdater = React.memo(() => {
  const payload = reportScadaUpdateSelector();
  const updatePayload = reportScadaUpdateDispatch();
  const plot = reportPlotSelector(payload?.plotKey, ['key', 'updatePath']);
  const updatePlot = reportPlotDispatch(payload?.plotKey);
  const updateError = reportErrorDispatch();

  const submitPlot = React.useCallback(() => {
    patchJSON(
      plot.updatePath,
      payload,
      (data) => {
        updatePlot(data.object);
        updatePayload(null);
      },
      () => {
        updateError({
          plotKey: plot.key,
          message: 'Failed to save changes.',
        });
      },
    );
  }, [plot, payload, updatePlot, updateError]);

  React.useEffect(() => {
    if (!payload) return;

    submitPlot();
  });

  return null;
});
