import * as React from 'react';
import {
  reportPlotKeysDispatch,
  reportPlotKeysSelector,
  MODAL_TYPES,
  reportPlotSelectedKeyDispatch,
  reportModalTypeDispatch,
  reportPlotDispatch, reportSelector,
} from '../report-utils';

export function ReportPlotNew({ newPlot, setNewPlot }) {
  const report = reportSelector(['viewMode']);
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updateSelectedPlotKey = reportPlotSelectedKeyDispatch();
  const updateSelectedModalType = reportModalTypeDispatch();
  const updatePlot = reportPlotDispatch(newPlot?.key);

  React.useEffect(() => {
    if (!newPlot) return;
    if (plotKeys.includes(newPlot.key)) return;

    updatePlot(newPlot);
    updatePlotKeys(plotKeys.concat(newPlot.key));

    if (report.viewMode !== 'scada') {
      updateSelectedPlotKey(newPlot.key);
      updateSelectedModalType(MODAL_TYPES.PLOT_GENERAL);
    }

    setNewPlot(null);
  });

  return null;
}
