import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { GlobalWrapper } from '../../components/GlobalWrapper.jsx';
import { Dashbox, DashboxBody, DashboxTitle } from '../../duxfront/duxdash/components/Dashbox.jsx';
import { Button } from '../../duxfront/duxstrap/components/Button.jsx';
import { Icon } from '../../duxfront/duxstrap/components/Icon.jsx';
import { Translator } from '../../global/translator';
import { useLocationGroupNavigation } from '../../hooks/use-location-group-navigation';
import { LocationGroupOptionModal } from './components/LocationGroupOptionModal.jsx';
import { LocationGroupAssigmentModal } from './components/LocationGroupAssignmentModal.jsx';
import { useLocationGroupAssignment } from '../../hooks/use-location-group-assignment';
import { useLocationGroupOptions } from '../../hooks/use-location-group-options';

export function LocationGroupAssignments(props) {
  const { option, locations } = props;

  return (
    <GlobalWrapper {...props}>
      <LocationGroupAssignmentsView selectedOption={option} locations={locations ?? []} />
    </GlobalWrapper>
  );
}

function LocationGroupAssignmentsView({ locations, selectedOption }) {
  const translator = new Translator();
  const { navigateGroupOptions } = useLocationGroupNavigation();
  const {
    option,
    setOption,
    modal: assignModal,
    showModal: showAssignModal,
    closeModal: closeAssignModal,
    assign,
    unassign,
  } = useLocationGroupAssignment(selectedOption);

  const {
    canEdit,
    modal: editOptionModal,
    showModal: showEditOptionModal,
    closeModal: closeEditOptionModal,
    update: updateOption,
    destroy: destroyOption,
  } = useLocationGroupOptions(selectedOption.group);

  const onEditOption = (title) => {
    updateOption(option.code, title);
    setOption({ ...option, title });
  };

  const onDestroyOption = () => {
    destroyOption(option.code);
  };

  return option && (
  <Container className="py-3">
    <Row>
      <Col sm={12}>
        <div className="fs-12 text-gray-600 text-uppercase">{translator.get('titles.location_group_option')}</div>
        <h4 className="font-weight-accent m-0">
          {option.title}
        </h4>
      </Col>

      <Col sm={12} className="d-flex mt-1">
        <Button withIcon size="xs" variant="outline-primary" onClick={() => navigateGroupOptions(option.group?.code)}>
          <Icon name="arrow-left" />
          {translator.get('general.back').toUpperCase()}
        </Button>

        {canEdit && (
        <>
          <Button
            withIcon
            size="xs"
            variant="outline-danger"
            className="ml-2"
            onClick={onDestroyOption}
          >
            <Icon name="trash" />
            {translator.get('general.remove').toUpperCase()}
          </Button>

          <Button
            withIcon
            size="xs"
            variant="outline-secondary"
            className="ml-2"
            onClick={() => showEditOptionModal(option)}
          >
            <Icon name="edit" />
            {translator.get('general.edit').toUpperCase()}
          </Button>
        </>
        )}
      </Col>

      <Col sm={12} className="mt-3">
        <Dashbox>
          <DashboxTitle title={translator.get('general.assignments')}>
            {canEdit && (
            <Button rounded icon size="sm" variant="outline-primary" onClick={showAssignModal}>
              <Icon name="edit" offset={1} />
            </Button>
            )}
          </DashboxTitle>

          <DashboxBody table>
            {!option.assignments || option.assignments.length === 0 ? (
              <tr>
                <td className="px-3">{translator.get('messages.no-results-found')}</td>
              </tr>
            ) : (
              option.assignments.map((a) => (
                <tr
                  key={a.id}
                  className="link-wrapper cursor-pointer"
                >
                  <td className="px-3">
                    {a.location.name}
                  </td>
                </tr>
              ))
            )}
          </DashboxBody>
        </Dashbox>
      </Col>
    </Row>

    <LocationGroupOptionModal
      option={option}
      show={editOptionModal}
      onClose={closeEditOptionModal}
      onSave={onEditOption}
    />

    <LocationGroupAssigmentModal
      locations={locations}
      option={option}
      show={assignModal}
      onClose={closeAssignModal}
      onAssign={(location) => assign(location)}
      onUnassign={(location) => unassign(location)}
    />
  </Container>
  );
}
