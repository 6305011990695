import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Translator } from '../../../global/translator';
import {
  loadSources, MODAL_TYPES, reportErrorDispatch, reportModalTypeDispatch,
  reportPlotOptionsSelector, reportScadaClickPositionDispatch, reportScadaClickPositionSelector,
  reportSelector, reportSourcesDispatch, reportSourcesSelector,
} from '../report-utils';
import { parseForm, patchJSON } from '../../../duxfront/plugins/dux-utils';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { ReportPlotNew } from '../report_plot_base/ReportPlotNew.jsx';
import { Select } from '../../../duxfront/duxstrap/components/Form.jsx';
import { ContextMenuItem } from '../../../duxfront/duxdash/components/ContextMenu.jsx';
import { ReportFormWrapper } from '../report_base/ReportFormWrapper.jsx';
import { ReportModalWrapper } from '../report_base/ReportModalWrapper.jsx';

export function ReportScadaNewLocationForm() {
  const self = React.createRef();
  const translator = new Translator();
  const plotOptions = reportPlotOptionsSelector();
  const updateClickPosition = reportScadaClickPositionDispatch();
  const report = reportSelector(['updatePath']);
  const updateError = reportErrorDispatch();
  const clickPosition = reportScadaClickPositionSelector();
  const sources = reportSourcesSelector();
  const setSources = reportSourcesDispatch();
  const formId = 'scada_location';
  const title = `${translator.get('general.add')} ${translator.get('titles.location')}`;
  const [newPlot, setNewPlot] = React.useState(null);
  const updateSelectedModalType = reportModalTypeDispatch();

  const addScadaLocation = React.useCallback((e) => {
    e.preventDefault();

    const formData = parseForm(e.target).scada_location;
    const payload = {
      authenticity_token: formData.authenticity_token,
      add_plot: {
        plot_type: 'scada_location',
        scada_location: {
          location: formData.location,
          image: formData.image,
          position: clickPosition,
        },
      },
    };

    patchJSON(
      report.updatePath,
      payload,
      (result) => {
        updateClickPosition(null);
        updateSelectedModalType(null);
        setNewPlot(result.added);
      },
      () => updateError('Failed to add new plot.'),
    );
  });

  React.useEffect(() => {
    if (!sources) loadSources(report, plotOptions, setSources, updateError);
  });

  return plotOptions && (
    <ReportModalWrapper
      modalType={MODAL_TYPES.SCADA_ADD_LOCATION}
      title={title}
    >
      <ReportFormWrapper
        self={self}
        formId={formId}
        onSave={addScadaLocation}
      >
        <Col sm={6}>
          <Select
            id="location"
            formId={formId}
            label={translator.get('titles.location')}
            options={sources}
          />
        </Col>
        <Col sm={6}>
          <Select
            id="image"
            formId={formId}
            label={translator.get('general.image')}
            options={plotOptions.plotImageOptions}
          />
        </Col>
      </ReportFormWrapper>
      { newPlot && (
        <ReportPlotNew newPlot={newPlot} setNewPlot={setNewPlot} />
      )}
    </ReportModalWrapper>
  );
}

export function ReportScadaNewLocation() {
  const translator = new Translator();
  const updateSelectedModalType = reportModalTypeDispatch();

  return (
    <ContextMenuItem onClick={() => updateSelectedModalType(MODAL_TYPES.SCADA_ADD_LOCATION)}>
      <Icon name="area-chart" className="text-primary mr-2" />
      { `${translator.get('general.add')} ${translator.get('titles.location')}` }
    </ContextMenuItem>
  );
}
