import * as React from 'react';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { DashboxFooter, DashboxTitle } from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { reportPlotInformationSelector } from '../report-utils';
import { plotConfigSelector } from '../report_interactive_plot/plot-utils';

export function ReportPlotBoxFullScreenIcon({ fullScreen, setFullScreen }) {
  return fullScreen ? (
    <Icon name="compress" className="cursor-pointer" onClick={() => setFullScreen(false)} />
  ) : (
    <Icon name="expand" className="cursor-pointer" onClick={() => setFullScreen(true)} />
  );
}

export function ReportPlotBoxHeader({ plotKey, children }) {
  const { plotConfig } = plotConfigSelector(plotKey);

  return (
    <DashboxTitle>
      <h4 className="m-0">{plotConfig.title}</h4>
      <div className="fs-14 text-gray-500 d-print-none">
        { children }
      </div>
    </DashboxTitle>
  );
}

export function ReportPlotBoxFooter({ plotKey }) {
  const plotInformation = reportPlotInformationSelector(plotKey);

  return plotInformation && (
    <DashboxFooter className="d-block">
      <pre className="p-0 m-0 text-left text-gray-600 fs-12 plot-information">
        {plotInformation}
      </pre>
    </DashboxFooter>
  );
}
