import React from 'react';
import { Project } from '../global/project';
import {
  postJSON, deleteJSON, patchJSON, getJSON,
} from '../duxfront/plugins/dux-utils';
import { BootBox } from '../duxfront/duxstrap/vendor/bootbox';
import { Translator } from '../global/translator';
import { useLocationGroupNavigation } from './use-location-group-navigation';

export function useLocationGroupOptions(locationGroup) {
  const translator = new Translator();
  const project = new Project();
  const canEdit = project.userCan('manage_project');
  const [group, setGroup] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const { navigateGroupOptions } = useLocationGroupNavigation();

  React.useEffect(() => {
    if (!locationGroup) return;
    setGroup(locationGroup);
  }, [locationGroup]);

  function refreshOptions() {
    getJSON(
      `/projects/${project.code}/location_groups/${group.code}/options`,
      {},
      (data) => {
        setGroup((prev) => ({ ...prev, options: data.object }));
      },
    );
  }

  function assignOption(option, location, assign) {
    if (!group) return;

    const action = assign ? 'assign' : 'unassign';

    postJSON(
      `/projects/${project.code}/locations_groups/${group.code}/${action}`,
      { location_id: location.code, option_id: option.code },
      () => refreshOptions(),
    );
  }

  function showModal() {
    if (!canEdit) return;
    setModal(true);
  }

  function closeModal() {
    refreshOptions();
    setModal(false);
  }

  function create(title) {
    postJSON(
      `/projects/${project.code}/location_groups/${group.code}/options`,
      { title },
      () => closeModal(),
    );
  }

  function update(id, title) {
    patchJSON(
      `/projects/${project.code}/location_groups/${group.code}/options/${id}`,
      { title },
      () => closeModal(),
    );
  }

  function destroy(id) {
    if (!id) return;
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;
        deleteJSON(
          `/projects/${project.code}/location_groups/${group.code}/options/${id}`,
          {},
          () => {
            closeModal();
            navigateGroupOptions(group.code);
          },
          (data) => BootBox.alert(`Error: ${data.responseJSON.msg || 'An unknown error occurred'}`),
        );
      },
    });
  }

  return {
    group,
    canEdit,
    modal,
    setGroup,
    showModal,
    closeModal,
    create,
    update,
    destroy,
    assignOption,
  };
}
