import React from 'react';
import { Project } from '../global/project';
import {
  postJSON, deleteJSON, patchJSON, getJSON,
} from '../duxfront/plugins/dux-utils';
import { BootBox } from '../duxfront/duxstrap/vendor/bootbox';
import { Translator } from '../global/translator';
import { useLocationGroupNavigation } from './use-location-group-navigation';

export function useLocationGroups() {
  const translator = new Translator();
  const project = new Project();
  const canEdit = project.userCan('manage_project');
  const [groups, setGroups] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const { navigateProjectDetails } = useLocationGroupNavigation();

  function getLocationGroups() {
    getJSON(
      `/projects/${project.code}/location_groups`,
      {},
      (data) => {
        setGroups(data.object);
      },
    );
  }

  React.useEffect(() => {
    getLocationGroups();
  }, []);

  function showModal() {
    if (!canEdit) return;
    setModal(true);
  }

  function closeModal() {
    getLocationGroups();
    setModal(false);
  }

  function create(title) {
    postJSON(`/projects/${project.code}/location_groups`, { title }, () => closeModal());
  }

  function update(id, title) {
    patchJSON(`/projects/${project.code}/location_groups/${id}`, { title }, () => closeModal());
  }

  function destroy(id) {
    if (!id) return;
    BootBox.confirm({
      size: 'small',
      message: translator.get('messages.are-you-sure'),
      callback: (success) => {
        if (!success) return;
        deleteJSON(
          `/projects/${project.code}/location_groups/${id}`,
          {},
          () => {
            closeModal();
            navigateProjectDetails();
          },
          (data) => BootBox.alert(`Error: ${data.responseJSON.msg || 'An unknown error occurred'}`),
        );
      },
    });
  }

  return {
    groups,
    canEdit,
    modal,
    showModal,
    closeModal,
    create,
    update,
    destroy,
  };
}
