import * as React from 'react';
import { ReportPlotModalWrapper } from '../report_plot_base/ReportPlotModalWrapper.jsx';
import { Translator } from '../../../global/translator';
import {
  MODAL_TYPES,
  reportModalTypeSelector,
  reportPlotMapsDispatch,
  reportPlotSelectedKeySelector,
  reportPlotSelector,
} from '../report-utils';
import { getJSON } from '../../../duxfront/plugins/dux-utils';
import { Project } from '../../../global/project';
import { LocationsMapSelection } from '../../maps/LocationsMapSelection.jsx';

export function ReportMapsModal() {
  const translator = new Translator();
  const reportPlotSelectedKey = reportPlotSelectedKeySelector();
  const plot = reportPlotSelector(reportPlotSelectedKey);
  const selectedModalType = reportModalTypeSelector();
  const updateMaps = reportPlotMapsDispatch(reportPlotSelectedKey);
  const show = plot && selectedModalType === MODAL_TYPES.PROJECT_MAP_SELECTION;
  const project = new Project();
  const [selectedMaps, setSelectedMaps] = React.useState([]);
  const [maps, setMaps] = React.useState([]);

  React.useEffect(() => {
    if (!show) return;

    getJSON(`/projects/${project.code}/maps`, {}, (data) => {
      setMaps(data.object);
    });
  }, [show]);

  const onSelectionChange = React.useCallback((newList) => {
    setSelectedMaps(newList);
    updateMaps(newList);
  });

  return show && (
    <ReportPlotModalWrapper
      title={translator.get('titles.map-selection')}
      modalType={MODAL_TYPES.PROJECT_MAP_SELECTION}
    >
      <LocationsMapSelection
        className="p-3"
        maps={maps}
        selectedMaps={selectedMaps}
        onSelectionChange={onSelectionChange}
      />
    </ReportPlotModalWrapper>
  );
}
