/* All components that will be rendered directly inside of views
 * must be imported here and added to global */

import { ProjectDetails } from './components/project/ProjectDetails.jsx';
import { LogList } from './components/log/LogList.jsx';
import { Permissions } from './components/project/Permissions.jsx';
import { NotificationLists } from './components/project/NotificationLists.jsx';
import { ObservationGroups } from './components/project/ObservationGroups.jsx';
import { LocationModules } from './components/location/LocationModules.jsx';
import { LocationObservations } from './components/location/LocationObservations.jsx';
import { Locations } from './pages/locations/Locations.jsx';
import { LocationMap } from './components/location/LocationMap.jsx';
import { ReportView } from './components/report/ReportView.jsx';
import { LocationDataVisualizationButton } from './components/location/LocationBox.jsx';
import { Dashboard } from './pages/dashboard/Dashboard.jsx';
import { CustomFormsIndex } from './components/custom_forms/CustomFormsIndex.jsx';
import { CustomForm } from './components/custom_forms/CustomForm.jsx';
import { Maps } from './components/project/Maps.jsx';
import { LocationGroups } from './components/project/LocationGroups.jsx';
import { LocationGroupDetails } from './pages/location_group/LocationGroupDetails.jsx';
import { LocationGroupAssignments } from './pages/location_group/LocationGroupAssignments.jsx';

global.ProjectDetails = ProjectDetails;
global.LogList = LogList;
global.Permissions = Permissions;
global.NotificationLists = NotificationLists;
global.LocationModules = LocationModules;
global.Locations = Locations;
global.LocationMap = LocationMap;
global.ReportView = ReportView;
global.LocationDataVisualizationButton = LocationDataVisualizationButton;
global.ObservationGroups = ObservationGroups;
global.Maps = Maps;
global.LocationGroups = LocationGroups;
global.LocationObservations = LocationObservations;
global.Dashboard = Dashboard;
global.CustomFormsIndex = CustomFormsIndex;
global.CustomForm = CustomForm;
global.LocationGroupDetails = LocationGroupDetails;
global.LocationGroupAssignments = LocationGroupAssignments;
