import * as React from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { Dashbox, DashboxBody } from '../../../duxfront/duxdash/components/Dashbox.jsx';
import { Icon } from '../../../duxfront/duxstrap/components/Icon.jsx';
import { Plot } from '../../../duxfront/duxdash/components/DashPlot.jsx';
import { Project } from '../../../global/project';
import {
  ReportPlotBoxFooter,
  ReportPlotBoxFullScreenIcon,
  ReportPlotBoxHeader,
} from '../report_plot_base/ReportPlotBox.jsx';
import { ReportPlotDataLoader } from '../report_plot_base/ReportPlotDataLoader.jsx';
import { ReportPlotRefreshData } from '../report_plot_base/ReportPlotRefreshData.jsx';
import { ReportPlotClone } from '../report_plot_base/ReportPlotClone.jsx';
import { ReportPlotDelete } from '../report_plot_base/ReportPlotDelete.jsx';
import { ReportPlotAdvanced } from '../report_plot_base/ReportPlotAdvanced.jsx';
import { ReportPlotGeneral } from '../report_plot_base/ReportPlotGeneral.jsx';
import { buildPlotPieConfig, plotConfigSelector, setPlotPieData } from './plot-utils';
import {
  ReportInteractivePlotDownload,
  ReportInteractivePlotCopy,
  ReportInteractivePlotZoomOut,
} from './ReportInteractivePlotBase.jsx';
import { reportPlotDataSelector, reportPlotSelector } from '../report-utils';

function ReportInteractivePieCustomPlotBox({ plotKey, plotApi, boxContentRef }) {
  const { plot, plotConfig } = plotConfigSelector(plotKey);
  const [fullScreen, setFullScreen] = React.useState(false);

  return plotConfig && !plotConfig.hidden && (
    <Col sm={plot.columnSize} className="print-no-page-break" style={{ order: plot.order }}>
      <Dashbox fullViewPort={fullScreen}>
        <ReportInteractivePieCustomPlotHeader
          plotKey={plot.key}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          plotApi={plotApi}
        />
        <ReportInteractivePieCustomPlotBody
          plotKey={plot.key}
          plotApi={plotApi}
          boxContentRef={boxContentRef}
        />
        <ReportPlotBoxFooter
          plotKey={plot.key}
        />
      </Dashbox>
    </Col>
  );
}

function ReportInteractivePieCustomPlotHeader({
  plotKey, fullScreen, setFullScreen, plotApi,
}) {
  const plot = reportPlotSelector(plotKey, ['key', 'title']);
  const project = new Project();

  return (
    <ReportPlotBoxHeader plotKey={plot.key}>
      <ReportInteractivePlotZoomOut plotApi={plotApi} />
      <ReportPlotBoxFullScreenIcon fullScreen={fullScreen} setFullScreen={setFullScreen} />
      { project.userCan('edit_reports') && (
        <Dropdown as="div" className="d-inline-block cursor-pointer ml-2">
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <Icon name="ellipsis-v" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ReportPlotRefreshData plotKey={plot.key} />
            <ReportPlotGeneral plotKey={plot.key} />
            <ReportInteractivePlotCopy plotApi={plotApi} />
            <ReportInteractivePlotDownload plotApi={plotApi} />
            { project.userCan('partner_actions') && (
              <>
                <ReportPlotClone plotKey={plot.key} />
                <ReportPlotDelete plotKey={plot.key} />
                <ReportPlotAdvanced plotKey={plot.key} />
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ReportPlotBoxHeader>
  );
}

const ReportInteractivePieCustomPlotBody = React.memo(({ plotKey, plotApi, boxContentRef }) => {
  const plot = reportPlotSelector(plotKey);
  const plotData = reportPlotDataSelector(plotKey);
  const plotConfig = buildPlotPieConfig(plot, plotData);

  React.useEffect(() => setPlotPieData(plot, plotApi.current, plotData));

  return (
    <DashboxBody ref={boxContentRef} className="p-2 overflow-hidden" style={{ height: plot.height }}>
      { plotData && (
        <Plot config={plotConfig} plotRef={plotApi} />
      )}
    </DashboxBody>
  );
});

export function ReportInteractivePieCustomPlot({ plotKey }) {
  const plot = reportPlotSelector(plotKey, ['key']);
  const plotApi = React.useRef();
  const boxContentRef = React.useRef();

  return plot && (
    <>
      <ReportPlotDataLoader
        plotKey={plot.key}
        boxContentRef={boxContentRef}
      />
      <ReportInteractivePieCustomPlotBox
        plotKey={plot.key}
        boxContentRef={boxContentRef}
        plotApi={plotApi}
      />
    </>
  );
}
