import React from 'react';
import { removePlot } from '../report_plot_base/ReportPlotDelete.jsx';
import {
  reportErrorDispatch, reportPlotKeysDispatch, reportPlotKeysSelector,
  reportPlotSelector, reportScadaDeleteDispatch, reportScadaDeleteSelector,
} from '../report-utils';

export const ReportScadaPlotRemover = React.memo(() => {
  const plotKey = reportScadaDeleteSelector();
  const plotKeys = reportPlotKeysSelector();
  const updatePlotKeys = reportPlotKeysDispatch();
  const updatePlotKey = reportScadaDeleteDispatch();
  const plot = reportPlotSelector(plotKey, ['key', 'updatePath']);
  const updateError = reportErrorDispatch();

  React.useEffect(() => {
    if (!plot) return;

    removePlot(plot, plotKeys, updatePlotKeys, updateError);
    updatePlotKey(null);
  });

  return null;
});
